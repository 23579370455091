<template>
    <div>
        <div class="result-panel">
            <CSTable :thead-top="filterHeight">
                <template v-slot:header>
                    <div class="table-header-panel text-right">
                        <button class="btn btn-primary sticky-right" @click="openAnnouncementDialog">
                            <svg
                                    class="icon"
                                    aria-hidden="true"
                            >
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            首页展示图
                        </button>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>添加时间</th>
                        <th>名称</th>
                        <th>发布时间</th>
                        <th>下架时间</th>
                        <th>状态</th>
                        <th>创建人</th>
                        <th>操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="banner in bannerList" :key=banner.id>
                        <td>{{banner.createTime}}</td>
                        <td><span class="allow-click"
                                  @click="lookAnnouncement(banner.url)">
                            {{ banner.name }}
                        </span>
                        </td>
                        <td>{{banner.sendTime}}</td>
                        <td>{{banner.outTime}}</td>
                        <td>{{banner.status == 1? '初始':banner.status == 2?'已上架':banner.status == 3?'已下架':'-'}}</td>
                        <td>{{banner.createUser}}</td>
                        <td>
                            <div class="btn-group">
                                <button
                                        type="button"
                                        class="btn btn-primary dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                >
                                    操作
                                </button>
                                <ul
                                        class="dropdown-menu"
                                        style="min-width: 76px; width: 76px; padding: 0"
                                >
                                    <li>
                                        <a style="width: 100%; padding: 3px 0;background-color: #cccccc !important; pointer-events:none"
                                           class="deleteBNtn" v-if="new Date(banner.outTime) < new Date()">
                                            {{(banner.status != 2)?'上架':'下架'}}</a>
                                        <a style="width: 100%; padding: 3px 0" v-else
                                           @click="putAwayBanner(banner.id,banner.status)">
                                            {{(banner.status != 2)?'上架':'下架'}}</a>
                                    </li>
                                    <li>
                                        <a style="width: 100%; padding: 3px 0" @click="editAnnouncementDialog(banner)">修改</a>
                                    </li>
                                    <li>
                                        <a style="width: 100%; padding: 3px 0" @click="deleteBanner(banner.id)">删除</a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </template>
            </CSTable>
            <Pagination name="Pagination" component="Pagination"></Pagination>
        </div>

        <CSDialog :dialog-visible="announcementDialog.visible" dialog-width="916px"
                  :dialog-title="announcementDialog.title"
                  dialog-confirm-btn-text="预览" @onClose="announcementDialog.onCancel"
                  @onConfirm="announcementDialog.onConfirm"
        >
            <template v-slot:dialog-content>
                <div class="form-panel">
                    <div class="form-item">
                        <div class="form-item-label">名称</div>
                        <div class="form-item-content">
                            <input type="text" placeholder="限20个字" maxlength="20" style="width: 400px;"
                                   class="cs-input" v-model="announcementDialog.data.name"/>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="form-item-label">发布时间</div>
                        <div class="form-item-content">
                            <CSSelect height="40px" i-width="36px">
                                <el-date-picker
                                        type="datetime"
                                        style="width: 264px;"
                                        v-model="announcementDialog.data.availableTime"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm"
                                        placeholder="请选择"
                                        prefix-icon="el-icon-time"
                                        :editable="false"
                                ></el-date-picker>
                            </CSSelect>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="form-item-label">下架时间</div>
                        <div class="form-item-content">
                            <CSSelect height="40px" i-width="36px">
                                <el-date-picker
                                        type="datetime"
                                        style="width: 264px;"
                                        v-model="announcementDialog.data.invalidTime"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm"
                                        placeholder="请选择"
                                        prefix-icon="el-icon-time"
                                        :editable="false"
                                ></el-date-picker>
                            </CSSelect>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="form-item-label top">首页展示图</div>
                        <div class="form-item-content">
                            <div style="max-width: 690px;">
                                <div class="photo-view"
                                     v-for="(resourceItem, index) in announcementDialog.data.resources" :key="index"
                                     :style="{width: '180px', height: '70px', marginBottom: announcementDialog.data.resources.length > 2 ? '10px' : '0'}"
                                     draggable @dragover="fileDragOver(index, $event)"
                                     @drop="fileDragEnd(index, $event)"
                                >
                                    <img v-if="resourceItem.type === 1" :src="resourceItem.url" alt=""
                                         @dragstart="fileDragStart(index, $event)">
                                    <video oncanplay="settingVolume" ref="localVideo" v-else :src="resourceItem.url"
                                           muted autoplay="autoplay" loop="loop"
                                           @dragstart="fileDragStart(index, $event)"
                                           style="width: 200px;height: 128px;"></video>
                                    <i class="icon-close" @click="deleteFile(index)"/>
                                </div>
                                <div class="upload-photo" style="width: 180px;height: 70px;"
                                     @click="triggerChooseFile"
                                     v-if="announcementDialog.data.resources.length < 8"
                                >
                                    <div class="text-center">
                                        <img src="@/assets/upload.png" style="margin: 5px auto 5px"/>
                                        <p style="color: #999">
                                            {{ announcementDialog.data.displayPosition === '1' ? '上传图片' :
                                            (announcementDialog.data.displayPosition != '' ? '上传图片/视频' : '')}}
                                        </p>
                                        <p style="color: #999">
                                            {{ announcementDialog.data.displayPosition !== '2' ? '尺寸720*280' :
                                            '请按照屏幕尺寸上传' }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-top: 17px;">
                                停留
                                <input type="number" v-model="announcementDialog.data.duration" class="cs-input"
                                       style="width: 180px;" placeholder="限整数3-300" maxlength="3">
                                秒
                            </div>
                            <input type="file" id="uploadFile"
                                   accept="image/gif, image/jpeg, image/png, video/mp4, audio/mp4, video/3gpp, audio/3gpp, video/avi"
                                   hidden
                                   @change="chooseFile($event)">
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>

        <!--        <Monitor :monitorVisible="announcementPreviewDialog.monitorVisible">-->
        <!--            <template v-slot:monitorContent>-->
        <!--                <div style="width: 644px; height:380px; position: absolute; top: 14px; left:14px; background: #fff;">-->
        <!--                    <el-carousel trigger="click" height="380px">-->
        <!--                        <el-carousel-item v-for="(item, index) in announcementPreviewDialog.data" :key="index">-->
        <!--                            <img v-if="item.type === 1" :src="item.url" alt="" style="width:100%;height:100%;">-->
        <!--                            <video v-else :src="item.url" autoplay="autoplay" muted loop="loop"-->
        <!--                                   style="width:100%;height:100%;"></video>-->
        <!--                        </el-carousel-item>-->
        <!--                    </el-carousel>-->
        <!--                </div>-->
        <!--            </template>-->
        <!--            <template v-slot:btnGroup>-->
        <!--                <template v-if="statePreview">-->
        <!--                    <button class="btn btn-primary" style="width:180px;height:40px;font-size:20px;margin-right: 50px;"-->
        <!--                            @click="announcementPreviewDialog.prevStep">上一步-->
        <!--                    </button>-->
        <!--                    <button class="btn btn-primary" style="width:180px;height:40px;font-size:20px;"-->
        <!--                            @click="announcementPreviewDialog.onConfirm">提交-->
        <!--                    </button>-->
        <!--                </template>-->
        <!--                <button v-else-->
        <!--                        style="display:block; width: 140px; height:40px; line-height: 20px; font-size:20px; margin: 0 auto;"-->
        <!--                        class="btn btn-primary" @click="announcementPreviewDialog.onClose">关闭-->
        <!--                </button>-->
        <!--            </template>-->
        <!--        </Monitor>-->


        <CSDialog
                :dialog-visible="announcementPreviewDialog.monitorVisible"
                dialog-title="预览图片"
                dialog-width="705px"
                :dialog-show-confirm-btn="true"
                dialog-cancel-btn-text="提交"
                dialogConfirmBtnText="上一步"
                @onClose="closeBtn"
                dialogHeaderClass="alert-bg"
                @onConfirm="refundOrder"

        >
            <template v-slot:dialog-content>
                <div style="padding:0 30px;margin: 0 auto ">
                    <div style="width: 644px; height:380px;  top: 14px; left:14px; background: #fff;">
                        <el-carousel trigger="click" height="380px">
                            <el-carousel-item v-for="(item, index) in announcementPreviewDialog.data" :key="index">
                                <img v-if="item.type === 1" :src="item.url" alt="" style="width:100%;height:100%;">
                                <video v-else :src="item.url" autoplay="autoplay" muted loop="loop"
                                       style="width:100%;height:100%;"></video>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>

            </template>
        </CSDialog>

        <CSDialog
                :dialog-visible="announcementPreviewDialog.monitorVisibles"
                dialog-title="查看图片"
                dialog-width="705px"
                :dialog-show-confirm-btn="false"
                dialog-cancel-btn-text="关闭"
                @onClose="announcementPreviewDialog.monitorVisibles = false"
                dialogHeaderClass="alert-bg"

        >
            <template v-slot:dialog-content>
                <div style="padding:0 30px;margin: 0 auto ">
                    <div style="width: 644px; height:380px;  top: 14px; left:14px; background: #fff;">
                        <el-carousel trigger="click" height="380px">
                            <el-carousel-item v-for="(item, index) in announcementPreviewDialog.data" :key="index">
                                <img v-if="item.type === 1" :src="item.url" alt="" style="width:100%;height:100%;">
                                <video v-else :src="item.url" autoplay="autoplay" muted loop="loop"
                                       style="width:100%;height:100%;"></video>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>

            </template>
        </CSDialog>

    </div>
</template>

<script>
    import CSTable from "@/components/common/CSTable";
    import CSSelect from "@/components/common/CSSelect";
    import CSDialog from "@/components/common/CSDialog";
    import Monitor from "@/components/Monitor";
    import {
        addBannerUrls,
        commonImgOssDomain,
        queryBannerUrls,
        deleteBannerUrls,
        putAwayBannerUrls, editBannerUrls,
    } from "@/requestUrl";
    import Pagination from "@/components/Pagination.vue";

    export default {
        name: "DisplayDiagram",
        components: {
            CSTable,
            CSSelect,
            CSDialog,
            Monitor,
            Pagination
        },
        data() {
            return {
                statePreview: false,
                filterHeight: 0,
                addRequestParams: {
                    name: "",
                    sendTime: "",
                    outTime: "",
                    urls: [],
                    duration: ""
                },
                announcementPreviewDialog: {
                    visible: false,
                    monitorVisible: false,
                    monitorVisibles:false,
                    data: [],
                    prevStep: () => {
                        this.announcementPreviewDialog.visible = false;
                        this.announcementPreviewDialog.monitorVisible = false;
                        this.announcementDialog.visible = true;
                        this.statePreview = false;
                    },
                    onConfirm: () => {
                    },
                    onClose: () => {
                        this.announcementPreviewDialog.visible = false;
                        this.announcementPreviewDialog.monitorVisible = false;
                        this.statePreview = false;
                    }
                },
                announcementDialog: {
                    visible: false,
                    title: '添加首页展示图',
                    data: {
                        name: '',
                        displayPosition: '',
                        groupId: '',
                        availableTime: '',
                        invalidTime: '',
                        duration: '',
                        resources: []
                    },
                    onCancel: () => {
                        this.announcementDialog.visible = false;
                    },
                    onConfirm: () => {
                        if (this.announcementDialog.data.resources.length === 0) {
                            this.$vc.toast('请上传配图');
                            return;
                        }
                        this.announcementPreviewDialog.data = this.announcementDialog.data.resources;
                        this.statePreview = true;
                        if (this.announcementDialog.data.displayPosition == 1) {
                            this.announcementPreviewDialog.visible = true;
                        } else {
                            this.announcementPreviewDialog.monitorVisible = true;
                        }
                        this.announcementDialog.visible = false;
                    }
                },
                bannerList: []
            }
        },
        created() {
            this.queryBannerList()
            this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
                this.queryBannerList(_currentPage);
            });
        },
        methods: {
            closeBtn(){
                if(!this.statePreview){
                    this.announcementPreviewDialog.monitorVisible = false
                }else{
                    this.announcementPreviewDialog.onConfirm();
                }

            },
            refundOrder(){
                this.announcementPreviewDialog.prevStep();
            },
            lookAnnouncement(resources) {
                let urls = [];
                for (let i = 0; i < resources.length; i++) {
                    urls.push({
                        type: 1,
                        url: resources[i]
                    })
                }
                this.statePreview = false;
                this.announcementPreviewDialog.data = urls;
                this.announcementPreviewDialog.monitorVisibles = true;
            },
            triggerChooseFile() {
                $("#uploadFile").trigger("click");
            },
            chooseFile(event) {
                let file = event.target.files[0];
                if (file.size > 300 * 1024 * 1024) {
                    this.$vc.toast("最大上传300MB视频，请重新上传。");
                    return;
                }
                if (!file.type.includes('image') && !file.type.includes('video')) {
                    this.$vc.toast("只可上传图片和视频");
                    return false;
                }
                this.speedProgressVisible = true
                file.needEncodeURI = true;
                this.$fly
                    .putFile(file, (ossObject) => {
                        this.speedProgress = ossObject.progress;
                        this.ossClient = ossObject.ossClient;
                    })
                    .then(res => {
                        this.speedProgressVisible = false;
                        if (file.size / 1024 / 1024 > 100) {
                            res.url = res.url.replace('http://cosmos-img.oss-cn-beijing.aliyuncs.com/', commonImgOssDomain);
                        }
                        this.announcementDialog.data.resources.push({
                            url: res.url,
                            type: file.type.includes('image') ? 1 : 2,
                        })
                    },)
                event.target.value = "";
            },
            deleteFile(index) {
                this.announcementDialog.data.resources.splice(index, 1);
            },
            settingVolume() {
                this.$refs.localVideo.volume = 0;
            },
            fileDragOver(index, e) {
                e.preventDefault();
            },
            fileDragEnd(index, e) {
                const dragStartIndex = Number(e.dataTransfer.getData("text/plain")),
                    {resources} = this.announcementDialog.data;
                if (dragStartIndex < index) {
                    resources.splice(index + 1, 0, resources[dragStartIndex]);
                    resources.splice(dragStartIndex, 1);
                }
                if (dragStartIndex > index) {
                    resources.splice(index, 0, resources[dragStartIndex]);
                    resources.splice(dragStartIndex + 1, 1);
                }
                e.dataTransfer.clearData("text/plain");
            },
            fileDragStart(index, e) {
                e.dataTransfer.setData('text/plain', index.toString());
            },
            openAnnouncementDialog() {
                this.announcementDialog.title = '添加首页展示图';
                this.announcementDialog.data = {
                    name: '',
                    displayPosition: '',
                    groupId: '',
                    availableTime: '',
                    invalidTime: '',
                    duration: '',
                    resources: []
                }
                this.announcementPreviewDialog.onConfirm = () => {
                    if (this.advertisingCount + this.announcementDialog.data.resources.length < 2) {
                        this.$vc.message('至少选择1张图片');
                        return;
                    }
                    let urls = [];
                    for (let resource of this.announcementDialog.data.resources) {
                        urls.push(resource.url)
                    }
                    let params = {
                        name: this.announcementDialog.data.name,
                        sendTime: this.announcementDialog.data.availableTime,
                        outTime: this.announcementDialog.data.invalidTime,
                        duration: this.announcementDialog.data.duration,
                        urls: urls
                    };
                    this.$fly.post(addBannerUrls, params
                    ).then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.$vc.toast('添加成功');
                        this.clearFormData();
                        this.announcementPreviewDialog.visible = false;
                        this.announcementPreviewDialog.monitorVisible = false;
                        this.statePreview = false;
                        this.queryBannerList();
                    })
                }
                this.statePreview = false;
                this.announcementDialog.visible = true;
            },
            clearFormData() {
                this.announcementDialog.data = {
                    name: '',
                    displayPosition: '',
                    groupId: '',
                    availableTime: '',
                    invalidTime: '',
                    duration: '',
                    resources: []
                };
            },
            queryBannerList(pageNo = 1, pageSize = 10) {
                this.$fly.post(queryBannerUrls, {
                        pageSize,
                        pageNo,
                        regionCode: this.$vc.getCurrentRegion().code
                    }
                ).then(res => {
                    if (res.code !== 0) {
                        return;
                    }
                    if (pageNo === 1 && res.data.datas) {
                        this.$vc.emit(this.$route.path, "pagination", "init", {
                            total: res.data.total,
                            currentPage: pageNo,
                            pageSize: res.data.total ? pageSize : res.data.datas.length,
                        });
                    }
                    this.bannerList = res.data.datas;
                })
            },
            deleteBanner(id) {
                this.$CSDialog.confirm({
                    title: '提示',
                    message: '确定删除吗?',
                    onConfirm: () => {
                        this.$fly.get(deleteBannerUrls, {
                                id
                            }
                        ).then(res => {
                            if (res.code !== 0) {
                                return;
                            }
                            this.$vc.toast('删除成功');
                            this.$CSDialog.instance.closeDialog();
                            this.queryBannerList()
                        })
                    }
                })
            },
            putAwayBanner(id, status) {
                let state = status !== 2;
                this.$fly.get(putAwayBannerUrls, {
                        id,
                        state
                    }
                ).then(res => {
                    if (res.code !== 0) {
                        return;
                    }

                    this.$vc.toast(state ? '上架成功' : '下架成功');
                    this.queryBannerList()
                })

            },
            async editAnnouncementDialog(announcement) {
                this.announcementDialog.title = '修改首页展示图';
                const resources = [];
                for (let i = 0; i < announcement.url.length; i++) {
                    resources.push({
                        type: 1,
                        url: announcement.url[i]
                    })
                }
                this.announcementDialog.data = {
                    name: announcement.name,
                    displayPosition: announcement.displayPosition,
                    groupId: announcement.groupId,
                    availableTime: announcement.sendTime,
                    invalidTime: announcement.outTime,
                    duration: announcement.duration,
                    resources,
                };
                this.announcementPreviewDialog.onConfirm = () => {
                    if (this.advertisingCount + this.announcementDialog.data.resources.length < 2) {
                        this.$vc.message('至少选择两个广告');
                        return;
                    }
                    let urls = [];
                    for (let resource of this.announcementDialog.data.resources) {
                        urls.push(resource.url)
                    }
                    let params = {
                        id: announcement.id,
                        name: this.announcementDialog.data.name,
                        sendTime: this.announcementDialog.data.availableTime,
                        outTime: this.announcementDialog.data.invalidTime,
                        duration: this.announcementDialog.data.duration,
                        urls: urls
                    };
                    this.$fly.post(editBannerUrls, params)
                        .then(res => {
                            if (res.code !== 0) {
                                return;
                            }
                            this.$vc.toast('修改成功');
                            this.clearFormData();
                            this.announcementPreviewDialog.visible = false;
                            this.announcementPreviewDialog.monitorVisible = false;
                            this.queryBannerList();
                        })
                }
                this.announcementDialog.visible = true;
            },
        }
    }
</script>

<style lang="stylus" scoped>
    .form-panel
        padding 27px 30px 30px 30px

        .form-item
            margin-bottom 25px
            font-size 0

            &-label
                text-align right
                width 120px
                display inline-block
                margin-right 40px
                vertical-align middle
                font-size 24px

                &.top
                    vertical-align top

            &-content
                display inline-block
                vertical-align middle
                font-size 24px

                .cs-input
                    &::placeholder
                        color #999
                    padding-left 10px
                    border-radius 4px
                    margin 0
                    border 1px solid #979797

                /deep/ .el-input__inner
                    font-size 24px

                    &::placeholder
                        color #999

                /deep/ .el-input__prefix
                    font-size 24px
                    line-height 20px

        .form-tips
            color #999
            font-size 20px

            p
                margin-bottom 0

    .rotate-out-center
        position relative
        top 50%
        left 50%
        transform translate(-50%, -50%)
        border-top 5px solid #fff
        border-radius 50%
        width 70px
        height 70px
        animation rotate-out-center 0.8s cubic-bezier(0.550, 0.085, 0.680, 0.530) both infinite

    @keyframes rotate-out-center
        0%
            transform translate(-50%, -50%) rotate(0)
        100%
            transform translate(-50%, -50%) rotate(360deg)

    .deleteBNtn {
        cursor: not-allowed;
    }

    .deleteBNtn:hover {
        background-color: #cccccc !important;
    }
</style>
